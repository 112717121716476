import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../../config/customBaseQuery";
import {
  AZURE_COST_OPTIMIZATION_BASE_URL,
  AZURE_COST_OPTIMIZATION_OVERVIEW_SUMMARY,
  TOP_5_OPPORTUNITIES,
  AZURE_OPTIMIZATION_FINDINGS_BREAKDOWN,
  AZURE_SPEND_VS_POTENTIAL_SAVINGS_CHART,
  OPPORTUNITIES_SUMMARY,
  AZURE_COST_OPPORTUNITIES_ACTION,
  AZURE_COST_OPTIMIZATION_COST_OPPORTUNITIES,
} from "../../../../config/service";
import { isArrayWithLength } from "../../../../utils/array-methods";

export const azureOptimizationOverviewApi = createApi({
  reducerPath: "azureOptimizationOverview",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAzureOptimizationSummary: builder.query({
      query: ({ subscriptionIds, tenure }) => {
        const encodedSubscriptionIds =
          isArrayWithLength(subscriptionIds) &&
          encodeURIComponent(JSON.stringify(subscriptionIds));
        return {
          url: `${AZURE_COST_OPTIMIZATION_BASE_URL}${AZURE_COST_OPTIMIZATION_OVERVIEW_SUMMARY}?Tenure=${tenure}${encodedSubscriptionIds ? `&SubscriptionIds=${encodedSubscriptionIds}` : ""}`,
          method: "GET",
        };
      },
    }),
    getTop5Opportunities: builder.query({
      query: ({ subscriptionIds, tenure, opportunityType }) => {
        const encodedSubscriptionIds =
          isArrayWithLength(subscriptionIds) &&
          encodeURIComponent(JSON.stringify(subscriptionIds));
        return {
          url: `${AZURE_COST_OPTIMIZATION_BASE_URL}${TOP_5_OPPORTUNITIES.replace("{opportunityType}", opportunityType)}?Tenure=${tenure}&Count=5${encodedSubscriptionIds ? `&SubscriptionIds=${encodedSubscriptionIds}` : ""}`,
          method: "GET",
        };
      },
    }),
    getAzureOptimizationFindingsBreakdown: builder.query({
      query: ({ subscriptionIds, tenure }) => {
        const encodedSubscriptionIds =
          isArrayWithLength(subscriptionIds) &&
          encodeURIComponent(JSON.stringify(subscriptionIds));
        return {
          url: `${AZURE_COST_OPTIMIZATION_BASE_URL}${AZURE_OPTIMIZATION_FINDINGS_BREAKDOWN}?Tenure=${tenure}${encodedSubscriptionIds ? `&SubscriptionIds=${encodedSubscriptionIds}` : ""}`,
          method: "GET",
        };
      },
    }),
    getSpendVsPotentialSavings: builder.query({
      query: ({ subscriptionIds, tenure }) => {
        const encodedSubscriptionIds =
          isArrayWithLength(subscriptionIds) &&
          encodeURIComponent(JSON.stringify(subscriptionIds));
        return {
          url: `${AZURE_COST_OPTIMIZATION_BASE_URL}${AZURE_SPEND_VS_POTENTIAL_SAVINGS_CHART}?Tenure=${tenure}${encodedSubscriptionIds ? `&SubscriptionIds=${encodedSubscriptionIds}` : ""}`,
          method: "GET",
        };
      },
    }),
    getOpportunitiesSummary: builder.query({
      query: ({
        opportunityType,
        subscriptionIds,
        serviceType,
        commitmentTerm,
        lookBackPeriod,
      }) => {
        const encodedSubscriptionIds =
          isArrayWithLength(subscriptionIds) &&
          encodeURIComponent(JSON.stringify(subscriptionIds));

        return {
          url: `${AZURE_COST_OPTIMIZATION_BASE_URL}${OPPORTUNITIES_SUMMARY.replace("{OpportunityType}", opportunityType)}?ServiceType=${serviceType}${encodedSubscriptionIds ? `&SubscriptionIds=${encodedSubscriptionIds}` : ""}${commitmentTerm ? `&CommitmentTerm=${commitmentTerm}` : ""}${lookBackPeriod ? `&LookBackPeriod=${lookBackPeriod}` : ""}`,
          method: "GET",
        };
      },
      transformResponse: (response, meta, arg) => ({
        data: response,
        serviceType: arg.serviceType,
      }),
    }),
    opportunitiesAction: builder.mutation({
      query: ({ SubscriptionId, RecommendationId, Action, Ttl }) => {
        const encodedTtl = Ttl ? `&TTL=${encodeURIComponent(Ttl)}` : "";
        return {
          url: `${AZURE_COST_OPPORTUNITIES_ACTION}/${RecommendationId}/${Action}?SubscriptionId=${encodeURIComponent(SubscriptionId)}${encodedTtl}`,
          method: "PUT",
        };
      },
    }),
    getAzureCostOptimizationOpportunitiesOptions: builder.query({
      query: ({
        FilterType,
        OpportunityType,
        Limit = 5,
        Offset = 0,
        KeywordSearch = "",
      }) => {
        // Construct the query string conditionally
        let queryParams = `?Limit=${Limit}&Offset=${Offset}`;

        // Only include KeywordSearch if it's truthy (non-empty)
        if (KeywordSearch) {
          queryParams += `&KeywordSearch=${encodeURIComponent(JSON.stringify([{ Field: FilterType, Value: KeywordSearch }]))}`;
        }

        return {
          url: `${AZURE_COST_OPTIMIZATION_COST_OPPORTUNITIES}/${OpportunityType}/lookup/${FilterType}${queryParams}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAzureOptimizationSummaryQuery,
  useGetTop5OpportunitiesQuery,
  useGetAzureOptimizationFindingsBreakdownQuery,
  useGetSpendVsPotentialSavingsQuery,
  useGetOpportunitiesSummaryQuery,
  useOpportunitiesActionMutation,
  useGetAzureCostOptimizationOpportunitiesOptionsQuery,
} = azureOptimizationOverviewApi;
