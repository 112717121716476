import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AUTH_TYPE , CONTACT_URL } from "../../constants";

const icommon = i18next.getFixedT(null, "common");

const RedirectTo = ({ type = AUTH_TYPE.LOGIN }) => {
  const { t } = useTranslation("loginForm");
  if (type === AUTH_TYPE.SIGNUP) {
    return (
      <div className="mc-h6-regular clearfix bs4-mt-3">
        {t("demo")}{" "}
        <a 
          className="mt-sm bs4-mt-2" 
          href={CONTACT_URL} 
          target="_blank" 
          rel="noopener noreferrer"
        >
        {t("Contact us")}
        </a>{" "}
        {icommon("here")}
      </div>
    );
  }
  return (
    <div className="mc-h6-regular clearfix bs4-mt-3">
      {t("takeMeto")}
      <Link className="bs4-ml-2" to="/login">
        {t("loginTitle")}
      </Link>
    </div>
  );
};

export default RedirectTo;
